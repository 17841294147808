<template>
  <div class="store">
    <div class="title">
      <div @click="goBack"><i class="el-icon-arrow-left"></i></div>

      <div>历史收入详情</div>
    </div>

    <div class="center">
      <div class="title-sum">
        <div class="sum-show">
          <div class="show-day">{{ day }}</div>
        </div>
        <div class="sum-text">
          <p>{{ todayObj.totalCapital }}</p>
          <p>当日收入（元）</p>
        </div>
      </div>
      <div class="search">
        <div class="search-left">
          收入来源占比
        </div>
        <div class="search-right">
          时段：00：00—23：59
        </div>
      </div>
      <div class="flex">
        <div class="flex-left">
          <div class="left-g">
            <div class="g-show"></div>
            <div class="g-item">
              现金金额：<span>{{ todayObj.cash }}</span>
            </div>
          </div>
          <div class="left-b">
            <div class="b-show"></div>
            <div class="b-item">
              移动金额：<span>{{ todayObj.mobile }}</span>
            </div>
          </div>
        </div>
        <div class="flex-right">
          <div id="pie"></div>
        </div>
      </div>
      <div class="bar">
        <div class="bar-search">
          <div class="search-left">
            收入明细
          </div>
          <div class="search-right">
            时段：00：00—23：59
          </div>
        </div>
        <div class="bar-title">
          <div class="title-left">
            <img src="~@/assets/img/total/ico.png" />
            <div class="item-sum">
              充值金额：<span>{{ moneyObj.networkFee }}</span>
            </div>
          </div>
          <div class="title-right">
            <img src="~@/assets/img/total/ico.png" />
            <div class="item-sum">
              商品金额：<span>{{ moneyObj.sale }}</span>
            </div>
          </div>
        </div>
        <div id="bar"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "store",
  data() {
    return {
      listData: [],
      todayObj: {}, // 今日收入保存
      moneyObj: {}, // 金额概况保存
      idxShow: 0,
      day: "",
      date: "",
      shopId: "",
    };
  },
  mounted() {
    this.day = this.$route.query.day;
    this.shopId = this.$route.query.shopId || localStorage.shopId;
    this.date = this.$route.query.dateTime + "-" + this.$route.query.day;
    this.handleFirstData();
    this.getAmountOfStatus();
  },
  methods: {
    getAmountOfStatus() {
      let _this = this;
      _this.$API.getAmountOfStatus({ shopId: this.shopId, date: this.date }).then(function(res) {
        if (res.code == 200) {
          _this.moneyObj = res.data;
          _this.handlebarData();
        } else {
          Toast(res.msg);
        }
      });
    },
    handleFirstData() {
      let dataTime = this.parseTime(new Date(), "{y}-{m}-{d}");
      let _this = this;
      _this.$API
        .getCapital({ shopId: this.shopId, date: this.date })
        .then(function(res) {
          if (res.code == 200) {
            _this.todayObj = res.data;
            _this.handlePieData();
          } else {
            Toast(res.msg);
          }
        });
    },
    // 环形图
    handlePieData() {
      //初始化
      let myChart = this.$echarts.init(document.getElementById("pie"));
      let num = this.todayObj.totalCapital;
      // 回执图表
      myChart.setOption({
        title: {
          zlevel: 0,
          subtext: "￥" + num,
          subtextStyle: {
            color: "#000000",
            fontSize: 16,
            fontWeight: 'bold'
          },
          x: "center", //文字位置
          y: "35%" //文字位置
        },
        tooltip: {
          show: false
        },
        legend: { show: false },
        graphic: [
          //为环形图中间添加文字
          {
            type: "text",
            left: "center",
            top: "center",
            style: {
              textAlign: "center",
              fill: "#222222",
              fontSize: 16
            }
          }
        ],
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: ["72%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "20",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: this.todayObj.cash,
                name: "现金收入",
                itemStyle: { color: "#5CD08C" }
              },
              {
                value: this.todayObj.mobile,
                name: "移动收入",
                itemStyle: { color: "#007EFE" }
              }
            ]
          }
        ]
      });
      window.onresize = myChart.resize;
    },
    // 柱形图
    handlebarData() {
      let that = this;
      let saleArr = [],networkFeeArr=[];
      saleArr.push(that.moneyObj.mobileSale,that.moneyObj.mobileNetworkFee)
      networkFeeArr.push(that.moneyObj.cashSale,that.moneyObj.cashNetworkFee)
      //初始化
      let myChart = that.$echarts.init(document.getElementById("bar"));
      // 回执图表
      myChart.setOption({
        tooltip: {
          show: false
        },
        color: ["#4DBB81", "#6D8EFA"],
        legend: { show: false },
        grid: {
          top: "2%",
          left: "0%",
          right: "6%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          axisLine: { 
            onZero: false ,
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#EDEDED", "#EDEDED"],
              type: 'dashed' // y轴分割线类型
            },
          },
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              // 使用深浅的间隔色
              color: ["#EDEDED", "#EDEDED"],
              type: 'dashed' // y轴分割线类型
            }
          },
          type: "category",
          data: ["商品", "充值"],
          axisTick: {
            show: false
          },
          axisLine: { 
            onZero: false ,
            show: false
          },
        },
        series: [
          {
            name: "充值",
            type: "bar",
            data: networkFeeArr, //设置柱状图大小
            barWidth: 16,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  // position: 'top',
                  // valueAnimation: true,
                  textStyle: {
                    color: "#fff"
                  },
                  formatter: function(params) {
                    if (params.value == 0) {
                      return params.value;
                    }
                  }
                },
                barBorderRadius: 4,
                color: function(params) {
                  var colorList = [
                    ["#4DBB81", "#61D890"],
                  ];
                  var index = params.dataIndex;
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length;
                  }
                  // return colorList[params.dataIndex]
                  // l
                  return new that.$echarts.graphic.LinearGradient(1, 0, 0, 1, [
                    {
                      offset: 0,
                      color: colorList[index][0]
                    },
                    {
                      offset: 1,
                      color: colorList[index][1]
                    }
                  ]);
                }
              }
            }
          },
          {
            name: "商品",
            type: "bar",
            data: saleArr, //设置柱状图大小
            barWidth: 16,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  // position: 'bottom',
                  // // valueAnimation: true,
                  textStyle: {
                    color: "#fff"
                  },
                  formatter: function(params) {
                    if (params.value == 0) {
                      return params.value;
                    }
                  }
                },
                barBorderRadius: 4,
                color: function(params) {
                  var colorList = [
                    ["#6D8EFA", "#2765F2"]
                  ];
                  var index = params.dataIndex;
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length;
                  }
                  // return colorList[params.dataIndex]
                  // l
                  return new that.$echarts.graphic.LinearGradient(1, 0, 0, 1, [
                    {
                      offset: 0,
                      color: colorList[index][0]
                    },
                    {
                      offset: 1,
                      color: colorList[index][1]
                    }
                  ]);
                }
              }
            }
          },
        ]
      });
      window.onresize = myChart.resize;
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss" scoped>
.store {
  padding-bottom: 20px;
  .title {
    display: flex;
    padding: 20px;
    align-items: center;
    i {
      font-size: 30px;
    }
    > div:nth-child(2) {
      flex: 5;
      text-align: center;
      font-size: 40px;
    }
  }
  .center {
    margin-top: 21px;
    .search {
      display: flex;
      padding-left: 30px;
      .search-left {
        font-size: 28px;
        color: #1A1A1A;
      }
      .search-right {
        flex: 6;
        padding-bottom: 30px;
        padding-right: 30px;
        color: #afb6cd;
        text-align: right;
        font-size: 24px;
        // border-bottom: 1px solid #e7e9ec;
        margin-top: 7px; 
      }
    }
    .title-sum {
      height: 180px;
      background: url("~@/assets/img/list/listBg3.png");
      background-size: 100% 100%;
      color: #fff;
      display: flex;
      // align-items: center;
      justify-content: center;
      .sum-show {
        position: relative;
        margin-top: 10px;
        width: 119px;
        height: 119px;
        text-align: center;
        line-height: 119px;
        background: url("~@/assets/img/list/headerBg.png");
        background-size: 100% 100%;
      }
      .show-day {
        position: absolute;
        color: #6d8efa;
        font-size: 30px;
        font-weight: bold;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 46%;
        transform: translate(-50%, -50%);
        z-index: 33;
      }
      .sum-text {
        margin-left: 10px;
        margin-top: 20px;
        > p:nth-child(1) {
          font-size: 36px;
        }
        > p:nth-child(2) {
          margin-top: 10px;
          font-size: 22px;
        }
      }
    }
    .center-pie {
      margin-top: 30px;
    }
    #pie {
      width: 100%;
      height: 300px;
    }
    .flex {
      display: flex;
      align-items: center;
      padding: 0 30px;
      .flex-left {
        font-size: 26px;
        .left-g {
          display: flex;
          align-items: center;
          .g-show {
            width: 12px;
            height: 12px;
            background: #5cd08c;
            border-radius: 4px;
          }
          .g-item {
            margin-left: 20px;
            span{
              color: #FD2F34;
            }
          }
        }
        .left-b {
          display: flex;
          align-items: center;
          margin-top: 33px;
          .b-show {
            width: 12px;
            height: 12px;
            background: #007efe;
            border-radius: 4px;
          }
          .b-item {
            margin-left: 20px;
            span{
              color: #FD2F34;
            }
          }
        }
      }
      .flex-right {
        margin-left: auto;
        #pie {
          width: 280px;
          height: 280px;
        }
      }
    }
    .bar {
      padding: 30px;
      h3 {
        color: #1a1a1a;
        font-size: 28px;
      }
      .bar-title {
        margin: 36px 0;
        display: flex;
        > div {
          display: flex;
          align-items: center;
          font-size: 26px;
          color: #000;
        }

        img {
          width: 18px;
          height: 22px;
        }
        .title-left {
        }
        .item-sum {
          margin-left: 12px;
        }
        .title-right {
          margin-left: 45px;
        }
      }
      #bar {
        width: 100%;
        height: 260px;
      }
      .bar-tip {
      }
      .bar-search {
        display: flex;
        .search-left {
          font-size: 28px;
          color: #1A1A1A;
        }
        .search-right {
          flex: 6;
          padding-bottom: 30px;
          color: #afb6cd;
          text-align: right;
          font-size: 24px;
          margin-top: 7px; 
        }
      }
    }
  }
}
</style>
