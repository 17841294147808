import { render, staticRenderFns } from "./income.vue?vue&type=template&id=0a44dc20&scoped=true&"
import script from "./income.vue?vue&type=script&lang=js&"
export * from "./income.vue?vue&type=script&lang=js&"
import style0 from "./income.vue?vue&type=style&index=0&id=0a44dc20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.6@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a44dc20",
  null
  
)

export default component.exports